<template>
  <div class="big-bg auth-layout-wrap" :style="{backgroundImage: 'url(' + `${publicPath}assets/images/bg-login-2.jpg `+ ')' }">
  <div class="auth-content">
    <div class="card o-hidden">
      <div>

        <div  class="row">
          <div class="col-md-6">
            <div class="p-4">
               {{$store.state.loginFailure}}
              <h1 class="mb-3 text-18 font-weight-bold">{{$t('signIn.text')}}</h1>
              <div v-if="loginFailure == true" class="alert alert-warning" role="alert">
                {{$t('invalidUsernameOrPass.text')}}
              </div>

              <form @submit.prevent="signIn" novalidate class="text-initial">
                <div class="form-group">
                  <label for="email">{{$t('mobileOrEmail.text')}}</label>
                  <input class="form-control form-control-rounded" id="email" type="text" required v-model="form.mobile_or_email">
                </div>

                <div class="form-group">
                  <label for="password">{{$t('password.text')}}</label>
                  <input class="form-control form-control-rounded" id="password" type="password" required
                         v-model="form.password">
                </div>

                <button class="btn btn-rounded btn-primary btn-block mt-2"

                        :disabled="buttonStatus">  {{ $store.state.buttonStatus ? $t('pleaseWait.text') : $t('signIn.text') }} <i v-if="$store.state.buttonStatus" class="fa fa-spinner fa-spin"></i></button>
              </form>


            </div>
          </div>
          <div class="col-md-6 text-center small-bg" :style="{backgroundSize:'cover' ,backgroundImage: 'url(' + `${publicPath}assets/images/photo-long-3.jpg `+ ')' }">

            <div  class="mt-4 p-3 text-center">
              <div v-if="settings.siteLogo == 'text' || settings.siteLogo == 'siteName'" class="font-weight-bold">{{title}}</div>
              <div><img :src="`${publicPath}assets/images/logo.png`" width="150" alt="" class="mb-3"></div>
              <div><img v-if="settings.siteLogo == 'image'" :src="settings.imageLight" width="150" alt="" class="rounded"></div>
            </div>

            <div class="auth-right">
<!--                      <a class="btn btn-rounded btn-warning btn-email btn-block btn-icon-text text-white"><i class="i-Mail-with-At-Sign"></i> Sign up with Email</a>-->
<!--                      <a class="btn btn-rounded btn-google btn-block btn-icon-text text-white"><i class="i-Google-Plus"></i> Sign up with Google</a>-->
<!--                      <a class="btn btn-rounded btn-block btn-icon-text btn-facebook text-white"><i class="i-Facebook-2"></i> Sign up with Facebook</a>-->
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
  </div>
</template>


<script>
import { mapActions ,mapGetters  } from 'vuex';

export default {
  components: {},
  data() {
    return {
      form: {
        password:'',
        email:''
      },
      publicPath: process.env.BASE_URL,
      register: process.env.VUE_APP_REGISTER,
      checkLogin: false,
      message: '',
      languages:[],
      settings:{
        siteLogo :"",
        settings:""
      },
      title:''
    }
  },


  mounted() {
  },
  created() {
    this.themeSettings();
  },

  methods: {
    ...mapActions({
      login: 'auth/login',
      languages:'dashboard/languages'
    }),
    signIn() {
        this.login(this.form).then(() => {
          this.$router.replace('/');
        });
    },
    themeSettings() {
      let colorTheme = "gradient-purple-indigo";
      let appAllWrap = document.querySelector(".app-all-wrap");
      let body = document.querySelector("body");
      appAllWrap.setAttribute('data-theme-color', colorTheme);
      body.setAttribute('data-theme-color', colorTheme);
    }
  },
  computed: {
    ...mapGetters({
      'loginFailure': 'auth/loginFailure',
      'buttonStatus': 'auth/buttonStatus',
      // ...
    })

  },
  watch: {
    $route() {

    },
  }
}
</script>

<style scoped>
.small-bg {
  background-position: bottom;
  background-size: cover;
}
.login-lang {position: fixed; right: 20px; top:20px; z-index: 9;}
[dir="rtl"] .login-lang {right: auto; left:20px}
.login-lang .fa-globe-americas {color: #fff; padding: 10px 20px; font-size: 20px;cursor: pointer;}
.auth-layout-wrap .auth-content {
  width: 100%;
  max-width: 700px !important;
}
</style>