import { createStore } from 'vuex'
import auth from './modules/auth'
import dashboard from './modules/dashboard'

export default createStore({
  state: {

  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth,
    dashboard
  }
})
