export default {
  "chgProfileData": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Change Profile Data"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chgProfileData"])}
  },
  "accountSettings": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Settings"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accountSettings"])}
  },
  "myInvoices": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["My Invoices"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["myInvoices"])}
  },
  "logout": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["logout"])}
  },
  "signIn": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sign In"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["signIn"])}
  },
  "fullName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Full name"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The full name including surname should be entered here."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fullName"])}
  },
  "password": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To enter  password definition to enter in Smart."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password"])}
  },
  "passwordConfirmation": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirm Password"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["password Confirmation"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To confirm  password definition to enter in Smart."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["passwordConfirmation"])}
  },
  "mobileOrEmail": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobile Or Email"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mobileOrEmail"])}
  },
  "countries": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Countries"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["countries"])}
  },
  "editCountry": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Country"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["editCountry"])}
  },
  "countryInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country Info"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["countryInfo"])}
  },
  "productInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Information"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productInfo"])}
  },
  "search": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Search"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["search"])}
  },
  "ID": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])}
  },
  "countryName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country Name"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the country name."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["countryName"])}
  },
  "countryCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country Code"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country Code"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the country code."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["countryCode"])}
  },
  "countryStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country Status"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country Status"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the country status."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["countryStatus"])}
  },
  "operations": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Operations"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["operations"])}
  },
  "edit": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["edit"])}
  },
  "toggleStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toggle Status"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["toggleStatus"])}
  },
  "noDataFound": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Data Found"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noDataFound"])}
  },
  "addCountry": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Country"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addCountry"])}
  },
  "cancel": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cancel"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cancel"])}
  },
  "allRightsReserved": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All Rights Reserved"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["allRightsReserved"])}
  },
  "terms": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terms"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["terms"])}
  },
  "requiredMsg": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Value is required For This Field"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["requiredMsg"])}
  },
  "minLengthMsg": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Minimum Length Allowed Is 2 For This Field"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minLengthMsg"])}
  },
  "maxLengthMsg": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Maximum Length Allowed Is 200 For This Field"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maxLengthMsg"])}
  },
  "maxLengthMsgTextArea": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Maximum Length Allowed Is 5000 For This Field"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maxLengthMsgTextArea"])}
  },
  "alertLangValidation": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Make Sure That All Tabs Of Language Requirements Are Completed!"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alertLangValidation"])}
  },
  "minValue": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum Value Is"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minValue"])}
  },
  "minLengthMsgMobile": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Minimum Length Allowed Is 7 For This Field"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minLengthMsgMobile"])}
  },
  "maxLengthMsgMobile": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The Maximum Length Allowed Is 20 For This Field"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maxLengthMsgMobile"])}
  },
  "numericValidationMsg": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Field Is For Entering Numbers Only"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["numericValidationMsg"])}
  },
  "emailInvailed": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Email Format Is Incorrect"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["emailInvailed"])}
  },
  "alphaMsg": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Field Accept English Language Only"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alphaMsg"])}
  },
  "slugValidation": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This Field Accept English Language , Numbers And Symbols Only"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slugValidation"])}
  },
  "noOptionsText": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The List Is Empty"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noOptionsText"])}
  },
  "invalidUsernameOrPass": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You Have Entered An Invalid Username Or Password!"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["invalidUsernameOrPass"])}
  },
  "addCurrency": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Currency"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addCurrency"])}
  },
  "editCurrency": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Currency"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["editCurrency"])}
  },
  "currencyInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency Info"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currencyInfo"])}
  },
  "currencies": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currencies"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currencies"])}
  },
  "currencyName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency Name"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the currency name."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currencyName"])}
  },
  "currencyCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency Code"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency Code"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the currency code."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currencyCode"])}
  },
  "currencyStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency Status"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency Status"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the currency status."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currencyStatus"])}
  },
  "exchangeRateCurrencies": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency Exchange Rate"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency Exchange Rate"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the currency exchange rate."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exchangeRateCurrencies"])}
  },
  "activeStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active Status"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activeStatus"])}
  },
  "currencySymbol": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency Symbol"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency Symbol"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the currency symbol."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currencySymbol"])}
  },
  "currencyFormat": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency Format"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency Format"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the currency format."])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currencyFormat"])}
  },
  "save": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["save"])}
  },
  "remove": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remove"])}
  },
  "areYouSure": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are You Sure ?"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["areYouSure"])}
  },
  "revertThis": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revert This"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["revertThis"])}
  },
  "deleteIt": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delete It"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["deleteIt"])}
  },
  "seen": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seen"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seen"])}
  },
  "to": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["To"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])}
  },
  "from": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])}
  },
  "records": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Records"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["records"])}
  },
  "first": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["First"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["first"])}
  },
  "last": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["last"])}
  },
  "fields": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fields"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fields"])}
  },
  "fieldsCreate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create Fields"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fieldsCreate"])}
  },
  "fieldsUpdate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update Fields "])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fieldsUpdate"])}
  },
  "fieldsInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fields Info"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fieldsInfo"])}
  },
  "fieldName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field Name"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the field name"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fieldName"])}
  },
  "fieldStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field Status"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Field Status"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the field status"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fieldStatus"])}
  },
  "sectors": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sectors"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sectors"])}
  },
  "sectorsCreate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sectors Create"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sectorsCreate"])}
  },
  "sectorsUpdate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sectors Update"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sectorsUpdate"])}
  },
  "sectorsInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sectors Info"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sectorsInfo"])}
  },
  "sectorsName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sector Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sector Name"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the sector name"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sectorsName"])}
  },
  "sectorsStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sector Status"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sector Status"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the sector status"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sectorsStatus"])}
  },
  "samples": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samples"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samples"])}
  },
  "samplesName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample Name"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the sample name"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samplesName"])}
  },
  "samplesStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample Status"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample Status"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the sample status"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samplesStatus"])}
  },
  "samplesSlug": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample Slug"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample Slug"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the sample slug"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samplesSlug"])}
  },
  "samplesCreate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sample Create"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samplesCreate"])}
  },
  "samplesUpdate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samples Update"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samplesUpdate"])}
  },
  "samplesInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Samples Info"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["samplesInfo"])}
  },
  "units": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Units"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["units"])}
  },
  "unitsCreate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Units Create"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unitsCreate"])}
  },
  "unitsUpdate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Units Update"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unitsUpdate"])}
  },
  "unitsInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Units Info"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unitsInfo"])}
  },
  "unitsName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit Name"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the unit name"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unitsName"])}
  },
  "unitsSlug": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit Slug"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit Slug"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the unit slug"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unitsSlug"])}
  },
  "unitsStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit Status"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unit Status"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the unit status"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unitsStatus"])}
  },
  "pleaseWait": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Wait"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pleaseWait"])}
  },
  "active": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["active"])}
  },
  "inactive": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactive"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["inactive"])}
  },
  "update": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["update"])}
  },
  "basicData": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Data"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basicData"])}
  },
  "productSettings": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Settings"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productSettings"])}
  },
  "modelUnit": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Models And Units"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["modelUnit"])}
  },
  "paymentSystem": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Payment System"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["paymentSystem"])}
  },
  "packages": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packages"])}
  },
  "packagesCreate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages Create"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packagesCreate"])}
  },
  "packagesName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages Name"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the package name"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packagesName"])}
  },
  "packagesSlug": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages Slug"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages Slug"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the package slug"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packagesSlug"])}
  },
  "packagesStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages Status"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages Status"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the package status"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packagesStatus"])}
  },
  "packagesUnits": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages Units"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages Units"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the package units"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packagesUnits"])}
  },
  "packagesInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages Info"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packagesInfo"])}
  },
  "packagesUpdate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Packages Update"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packagesUpdate"])}
  },
  "UltimateSolutions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ultimate Solutions"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["UltimateSolutions"])}
  },
  "package": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["package"])}
  },
  "packageName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Package Name"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["packageName"])}
  },
  "otherSettings": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other Settings"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["otherSettings"])}
  },
  "productName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productName"])}
  },
  "productStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Status"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productStatus"])}
  },
  "domain": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domain"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["domain"])}
  },
  "language": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["language"])}
  },
  "ar": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arabic"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ar"])}
  },
  "en": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["English"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en"])}
  },
  "clientCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Code"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clientCode"])}
  },
  "tradeName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercial name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tradeName"])}
  },
  "productLogo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Logo"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productLogo"])}
  },
  "shoppingCountry": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shopping Country"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["shoppingCountry"])}
  },
  "additionalInformation": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional Information"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["additionalInformation"])}
  },
  "dropFiles": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Drop Files Anywhere To Upload Or"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dropFiles"])}
  },
  "yes": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yes"])}
  },
  "no": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no"])}
  },
  "fromModel": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From a sample"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fromModel"])}
  },
  "MCQ": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Question type options MCQ"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MCQ"])}
  },
  "exam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quizzes and exercises + Exam"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["exam"])}
  },
  "HR": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Human Resources HR"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HR"])}
  },
  "library": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Library"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["library"])}
  },
  "forum": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forum"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forum"])}
  },
  "chooseModel": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Model"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chooseModel"])}
  },
  "ZidneyAcademySkills": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zidney academy, Zidney skills"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ZidneyAcademySkills"])}
  },
  "chooseBasicData": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choose Basic Data"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["chooseBasicData"])}
  },
  "division": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Division"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["division"])}
  },
  "seasons": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seasons"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seasons"])}
  },
  "materials": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materials"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["materials"])}
  },
  "categories": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categories"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["categories"])}
  },
  "other": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Other"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["other"])}
  },
  "productOwnership": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product ownership"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productOwnership"])}
  },
  "specialZidney": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special Zidney"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["specialZidney"])}
  },
  "specificCustomer": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific customer B2B"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["specificCustomer"])}
  },
  "commissionZidney": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zidney commission for each user"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commissionZidney"])}
  },
  "limitedContentSet": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limited content settings"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["limitedContentSet"])}
  },
  "contain": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contain"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contain"])}
  },
  "limited": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limited"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["limited"])}
  },
  "unlimited": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unlimited"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["unlimited"])}
  },
  "basicDataName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Data Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Data Name"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the date name"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basicDataName"])}
  },
  "basicDataSlug": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Data Slug"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Data Slug"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the date slug"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basicDataSlug"])}
  },
  "basicDataStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Data Status"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Data Status"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the date status"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basicDataStatus"])}
  },
  "basicDataCreate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Data Create"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basicDataCreate"])}
  },
  "basicDataShowInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic DataShow Info"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basicDataShowInfo"])}
  },
  "basicDataUpdate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Data Update"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basicDataUpdate"])}
  },
  "lamitedContent": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lamited Content"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lamited Content"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the lamited content"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lamitedContent"])}
  },
  "clientProducts": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Client Products"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clientProducts"])}
  },
  "products": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["products"])}
  },
  "productsPricesCreate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Products Prices Create"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productsPricesCreate"])}
  },
  "productsPricesUpdateV": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products Prices Update"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productsPricesUpdateV"])}
  },
  "productsPrices": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Products Prices"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productsPrices"])}
  },
  "clientsB2B": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients B2B"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clientsB2B"])}
  },
  "partnersB2B": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners B2B"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["partnersB2B"])}
  },
  "individualsPartners": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Individuals Partners"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["individualsPartners"])}
  },
  "name": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["name"])}
  },
  "status": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This field is used to enter the  status"])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["status"])}
  },
  "email": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["email"])}
  },
  "type": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["type"])}
  },
  "target": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Target"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["target"])}
  },
  "phone": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phone"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["phone"])}
  },
  "address": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Address"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["address"])}
  },
  "create": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Create"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["create"])}
  },
  "clientName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clientName"])}
  },
  "webSite": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Site"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["webSite"])}
  },
  "governmental": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Governmental"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["governmental"])}
  },
  "privateSector": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Private Sector"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["privateSector"])}
  },
  "profit": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profit"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profit"])}
  },
  "NonProfit": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non Profit"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NonProfit"])}
  },
  "sector": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sector"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sector"])}
  },
  "areasInterest": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Areas of interest"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["areasInterest"])}
  },
  "description": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["description"])}
  },
  "PromoCodes": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promo Codes"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PromoCodes"])}
  },
  "codeName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["codeName"])}
  },
  "commissionPercentage": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commission %"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commissionPercentage"])}
  },
  "discountPercentage": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["discount %"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["discountPercentage"])}
  },
  "activationStart": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activation start"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activationStart"])}
  },
  "activationEnd": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activation End"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activationEnd"])}
  },
  "code": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["code"])}
  },
  "branchOf": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["branch of"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["branchOf"])}
  },
  "PromoCodeType": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promo Code type"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PromoCodeType"])}
  },
  "general": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["general"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["general"])}
  },
  "forCompanies": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for companies"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forCompanies"])}
  },
  "forIndividuals": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for individuals"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forIndividuals"])}
  },
  "forSubscribers": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for subscribers"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forSubscribers"])}
  },
  "users": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["users"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["users"])}
  },
  "activated": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activated"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activated"])}
  },
  "notActivated": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["not activated"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notActivated"])}
  },
  "clients": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clients"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clients"])}
  },
  "dashboard": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dashboard"])}
  },
  "usersCount": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["usersCount"])}
  },
  "productPrice": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Price"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productPrice"])}
  },
  "productPrices": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Prices"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productPrices"])}
  },
  "addPrice": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Price"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addPrice"])}
  },
  "country": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Country"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["country"])}
  },
  "currency": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Currency"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" "])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["currency"])}
  },
  "from500to2000": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From 500 to 2000"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from500to2000"])}
  },
  "oneUser": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One User"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oneUser"])}
  },
  "oneMonth": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["One Month"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oneMonth"])}
  },
  "threeMonths": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 Month"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["threeMonths"])}
  },
  "sixMonths": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["6 Months"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sixMonths"])}
  },
  "twelveMonths": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["12 Months"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["twelveMonths"])}
  },
  "userNumbers": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User Numbers"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["userNumbers"])}
  },
  "duration": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["duration"])}
  },
  "universal": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Universal"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["universal"])}
  },
  "pleaseReviseData": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Please Revise Data"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pleaseReviseData"])}
  },
  "TrialSettings": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trial version settings"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TrialSettings"])}
  },
  "licenses": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Licenses"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licenses"])}
  },
  "license": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["license"])}
  },
  "client": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["client"])}
  },
  "product": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["product"])}
  },
  "activation": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activation"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activation"])}
  },
  "startOfActivation": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start Of Activation"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["startOfActivation"])}
  },
  "activationExpires": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activation Expires"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["activationExpires"])}
  },
  "addLicense": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add License"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addLicense"])}
  },
  "licenseDuration": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License Duration"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licenseDuration"])}
  },
  "price": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["price"])}
  },
  "additionalInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional Information"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["additionalInfo"])}
  },
  "licenseNumber": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License Number"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licenseNumber"])}
  },
  "licenseStartDate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License Start Date"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licenseStartDate"])}
  },
  "licenseEndDate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["license End Date"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licenseEndDate"])}
  },
  "add": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add"])}
  },
  "rangedUsers": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranged users"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rangedUsers"])}
  },
  "removeRange": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remove Range"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["removeRange"])}
  },
  "addRange": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["add Range"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addRange"])}
  },
  "for1month": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for 1 month"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for1month"])}
  },
  "for3month": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for 3 month"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for3month"])}
  },
  "for6month": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for 6 month"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for6month"])}
  },
  "for12month": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for 12 month"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["for12month"])}
  },
  "subdomain": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Subdomain"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["subdomain"])}
  },
  "localCurrency": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Local Currency"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["localCurrency"])}
  },
  "foreignCurrency": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foreign Currency"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["foreignCurrency"])}
  },
  "hasPromoCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Has Promo Code"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hasPromoCode"])}
  },
  "promoCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promo Code"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["promoCode"])}
  },
  "discount": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["discount"])}
  },
  "priceAfter": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price After Discount"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["priceAfter"])}
  },
  "additionalDiscount": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Additional Discount"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["additionalDiscount"])}
  },
  "netPaymentAmount": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Net Payment Amount"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["netPaymentAmount"])}
  },
  "licenseInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License Info"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licenseInfo"])}
  },
  "contentPartners": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Content Partners"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contentPartners"])}
  },
  "promoCodeInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promo Code Info"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["promoCodeInfo"])}
  },
  "settings": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settings"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["settings"])}
  },
  "mailsmsTemplates": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail / SMS Templates"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mailsmsTemplates"])}
  },
  "listTemplates": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List Templates"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["listTemplates"])}
  },
  "templateTitle": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Template Title"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["templateTitle"])}
  },
  "addTemplate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Template"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addTemplate"])}
  },
  "mailTemplate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mail Template"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mailTemplate"])}
  },
  "smsTemplate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SMS Template"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["smsTemplate"])}
  },
  "allowedVars": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variables That Can Be Used"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["allowedVars"])}
  },
  "editTemplate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Template"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["editTemplate"])}
  },
  "alertTitle": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert title"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alertTitle"])}
  },
  "alertMessage": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alert message"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alertMessage"])}
  },
  "profilesRoles": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiles and Roles"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profilesRoles"])}
  },
  "profileName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profileName"])}
  },
  "permissions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permissions"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["permissions"])}
  },
  "extraUnits": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra Units"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["extraUnits"])}
  },
  "extraUnitsCreate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra Units Create"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["extraUnitsCreate"])}
  },
  "extraUnitsUpdate": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra Units Update"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["extraUnitsUpdate"])}
  },
  "extraUnitsName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra Unit Name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["extraUnitsName"])}
  },
  "extraUnitsSlug": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra Unit Slug"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["extraUnitsSlug"])}
  },
  "extraUnitsStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra Unit Status"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["extraUnitsStatus"])}
  },
  "extraUnitsPrice": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra Units Price"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["extraUnitsPrice"])}
  },
  "zidneyPayment": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zidney Payment"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zidneyPayment"])}
  },
  "commission": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commission"])}
  },
  "default_commission": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Default Commission"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["default_commission"])}
  },
  "title": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["title"])}
  },
  "roleName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role name"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["roleName"])}
  },
  "invalidPromoCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid Promo Code"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["invalidPromoCode"])}
  },
  "basicSettings": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basic Settings"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["basicSettings"])}
  },
  "usersAndPermissions": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Users And Permissions"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["usersAndPermissions"])}
  },
  "site_title": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site Title"])},
    "helpTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "helpDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["site_title"])}
  },
  "depHasEducational": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Department Has Educationl Level"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["depHasEducational"])}
  },
  "noResultsText": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No Result"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["noResultsText"])}
  },
  "fieldsName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fields Name"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fieldsName"])}
  },
  "fieldsStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fields Status"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fieldsStatus"])}
  },
  "productCode": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Code"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productCode"])}
  },
  "addProduct": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Product"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addProduct"])}
  },
  "productDescription": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Description"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["productDescription"])}
  },
  "backOfficeUsers": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backoffice Users"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["backOfficeUsers"])}
  },
  "durationOfUse": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Duration Of Use"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["durationOfUse"])}
  },
  "licenseStartData": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License Start Date"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licenseStartData"])}
  },
  "licenseEndData": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["License End Date"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["licenseEndData"])}
  },
  "alerts": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerts"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alerts"])}
  },
  "addAlert": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Alert"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addAlert"])}
  },
  "editAlert": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Alert"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["editAlert"])}
  },
  "action": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["action"])}
  },
  "partners": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["partners"])}
  },
  "teams": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teams"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["teams"])}
  },
  "customStatus": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Custom Status"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["customStatus"])}
  },
  "typesOfWorkTeams": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Types Of Workteams"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["typesOfWorkTeams"])}
  },
  "workTeamName": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workteam Name"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["workTeamName"])}
  },
  "addWorkTeam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add Workteam"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["addWorkTeam"])}
  },
  "editWorkTeam": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit Workteam"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["editWorkTeam"])}
  },
  "workTeamInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Workteam Information"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["workTeamInfo"])}
  },
  "alertsInfo": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alerts Information"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alertsInfo"])}
  },
  "username": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["username"])}
  },
  "role": {
    "text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
    "helpTitle": null,
    "helpDescription": null,
    "code": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["role"])}
  }
}