<template>
  <div class="m-2" v-if="formValidation[rows].$each.$response.$errors.length">
    <div class="form-group " v-if="formValidation[rows] && formValidation.$dirty && formValidation[rows].$each.$response.$errors[index][inputToCheck].length">
      <div class="d-flex justify-content-between" v-for="error in formValidation[rows].$each.$response.$errors[index][inputToCheck]" :key="error" style="color:red;">
        <span v-if="error.$validator == 'required'">{{$t('requiredMsg.text')}}</span>
      </div>
    </div>
  </div>

</template>
<script>

export default {
  props: ['formValidation', 'inputToCheck', 'isDirty', 'index', 'rows'],
}
</script>