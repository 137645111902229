<template>
  <div>

    <div v-if="showDashboard" class="app-admin-wrap layout-sidebar-vertical sidebar-gradient-purple-indigo sidebar-full">

      <AppSidebar></AppSidebar>

      <div class="switch-overlay"></div>
      <div class="main-content-wrap mobile-menu-content m-0">

        <AppHeader></AppHeader>

        <AppLoading></AppLoading>

        <div class="mb-5 pb-5"><router-view/></div>
        <AppFooter></AppFooter>
      </div>

    </div>


    <AppCustomizer></AppCustomizer>
  </div>
</template>

<script>
import AppFooter from '@/components/Layout/app-footer'
import AppHeader from '@/components/Layout/app-header.vue'
import AppCustomizer from '@/components/Layout/app-customizer.vue'
import AppSidebar from '@/components/Layout/app-sidebar.vue'
import AppLoading from "@/components/Layout/app-loading";

export default {
  name: 'HomeView',
  components: {
    AppLoading,
    AppFooter,
    AppHeader,
    AppCustomizer,
    AppSidebar
  },
  data () {
    return {
      publicPath: process.env.BASE_URL,
      showDashboard:false
    }
  },
  created() {
    this.showDashboard = true;
  },
  methods: {
  },
  mounted() {
  },
  computed: {
  },
  beforeCreate() {
  },
  watch: {
  },

}
</script>
