import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import { VueEditor } from "vue3-editor";
import Swal from 'sweetalert2'
import axios from "axios";
import Toaster from '@meforma/vue-toaster';
import Pagination from 'v-pagination-3';
import TheMessageValidation from '@/components/Shared/TheMessageValidation';
import TheMessageValidationLang from '@/components/Shared/TheMessageValidationLang';
import TheMessageTabsValidation from '@/components/Shared/TheMessageTabsValidation';
import TheRowValidation from '@/components/Shared/TheRowValidation';
import TheErrorMessage from '@/components/Shared/ErrorMessage';
import TheLabel from '@/components/Shared/Label';
import Multiselect from 'vue-multiselect'
import FileUpload from 'vue-upload-component';
import TheHelp from '@/components/Shared/Help';
import Activate from '@/components/Shared/Activate';
import Delete from '@/components/Shared/Delete';
import { serialize } from 'object-to-formdata';
import 'v-calendar/dist/style.css';
import { setupCalendar, Calendar, DatePicker } from 'v-calendar';
import moment from 'moment'
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';
require('@/store/modules/subscriber')

const serializeOptios = {
    /**
     * include array indices in FormData keys
     * defaults to false
     */
    indices: true,

    /**
     * treat null values like undefined values and ignore them
     * defaults to false
     */
    nullsAsUndefineds: false,

    /**
     * convert true or false to 1 or 0 respectively
     * defaults to false
     */
    booleansAsIntegers: false,

    /**
     * store arrays even if they're empty
     * defaults to false
     */
    allowEmptyArrays: false,
};
let app = createApp(App)

app.config.globalProperties.$Swal = Swal;
app.config.globalProperties.$axios = axios;
app.config.globalProperties.$serialize = serialize;
app.config.globalProperties.$serializeOptios = serializeOptios;
app.config.globalProperties.$moment=moment;


app.component('pagination',Pagination);
app.component('message-validation', TheMessageValidation);
app.component('message-validation-lang', TheMessageValidationLang);
app.component('message-tabs-validation', TheMessageTabsValidation);
app.component('row-validation', TheRowValidation);
app.component('error-message', TheErrorMessage);
app.component('vue-multiselect', Multiselect)
app.component('FileUpload',FileUpload);
app.component('Help',TheHelp);
app.component('LabelC',TheLabel);
app.component('Activate',Activate);
app.component('Delete',Delete);
app.component('VCalendar', Calendar)
app.component('VDatePicker', DatePicker)
app.component('VueEditor',VueEditor);

app.use(setupCalendar, {})



store.dispatch('auth/attempt',localStorage.getItem('token')).then(() => {
    app.use(i18n).use(Toaster,{  position: "top-right",duration:4000})
    .use(store).use(router).mount('#app')

    app.mixin({
        methods: {
            checkPermission(permission){
                let user = store.getters["auth/user"].role_id ;
                if(user !== 1) {
                    let index = store.getters["auth/permissions"].findIndex(x => x.name === permission);
                    if(index == -1) {
                        return false
                    }else{
                        return true
                    }
                }else {
                    return true;
                }

            },
            checkPermissionLink(permission){
                let user = store.getters["auth/user"].role_id ;
                if(user !== 1) {
                    let index = store.getters["auth/permissions"].findIndex(x => x.model_name === permission);
                    if(index == -1) {
                        return false
                    }else{
                        return true
                    }
                }else {
                    return true;
                }

            }

        }
    })

})





