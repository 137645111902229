import axios from "axios";

export default {
    namespaced: true,
    state: {
        languages : [],

    },
    getters: {
        languagesTabs (state) {
          return   state.languages.map(d => ({
                languageName: d.languageName,
                language_universal: d.language_universal,
                name: "",

            }))
        },
    },
    mutations: {
        SET_LANGUAGES(state, languages) {
            state.languages = languages;
        },

    },
    actions: {
   async languages({commit}){
          let response = await  axios.get('list/languages');
          if (response.data.code == 200) {
              commit('SET_LANGUAGES',response.data.data);
          }
      },

    }
}
